/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 09.09.2022, 12:21:02
    Author     : paeg
*/

table.docdata {
	border-color: $font-color;
	color: $font-color;
}

.scrollable table thead tr,
.scrollable table tr,
.scrollable table tr:nth-child(2n),
.scrollable table tfoot{
	background: transparent;
	border-bottom: 1px solid $font-color;
}

.scrollable table thead tr:last-child {
	border-bottom: 1px solid $font-color;
}

table.docdata tr:not(:last-child) td {
	border-bottom-color: $font-color;
	border-right-color: $font-color;
}

#clsearch {
	align-items: baseline;
	display: flex;
	gap: 10px;
	
	input[type="text"] {
		background: transparent;
		border: 0;
		border-bottom: 1px solid $white;
		color: $font-color;
		font-size: 1em;
		padding: 5px;

		&::placeholder {
			color: transparentize($font-color, .3);
			opacity: 1;
		}

		&:focus {
			background: transparent;
		}
	}

	button, input[type="submit"] {
		background: 0 0;
		border: 1px solid #fff;
		color: #fff;
		letter-spacing: 2px;
		margin: 0;
		padding: 5px 20px;
		text-decoration: none;
		text-transform: uppercase;
		-webkit-transition: background-color .3s ease,color .3s ease;
		transition: background-color .3s ease,color .3s ease;
	}
	
	select {
		appearance: none;
		background: url(/mcont/img/chevron-down-solid.png) right center no-repeat transparent;
		border: 0;
		border-bottom: 1px solid $white;
		color: $white;
		font-size: 1em;
		height: 32px;
		padding: 7px;

		option {
			color: $black;
		}
	}
	
	div:first-of-type {
		display: flex;
		gap: 10px;
	}
}

.documentdownloads {
	background: transparent;
	
	.tr.dochead {
		background: none;
	}
}