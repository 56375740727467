/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 01.09.2022, 13:26:02
    Author     : paeg
*/

#mp-list .mportallistentry {
	background: transparent;
	border-bottom: 1px solid $white;
	padding-bottom: 20px;
	
	>:not(.mp-description){
		flex: 0 0 auto;
		margin-right: 50px;
	}
	
	>:not(.locked):hover::before {
		color: $white;
	}

	.mp-title {
		font-weight: bold;
		width: 250px;
		
		
		.title {
			font-size: 1.1em;
			font-weight: normal;
			margin-bottom: 10px;
		}
	}
	
	.mp-entryprogress .progressbar {
		align-items: center;
		display: flex;
		height: 1px;
		
		.progress {
			height: 3px;

			&::after {
				background: white;
				content: '';
				width: 7px;
				height: 7px;
				display: block;
				border-radius: 5px;
				position: absolute;
				right: 0;
				top: -2px;
			}
		}
	}
	
	@media screen and (max-width: 800px) {
		align-items: center;
		flex-direction: column;
		
		.mp-title {
			margin-top: 10px;
			width: auto;
		}
	}
}

#mpcontent #mportalbreadcrumbs {
	font-size: 1em;
	list-style-image: none;
}