/*

*) Socials Farbe auf weißem BG
*) BG

radial-gradient(circle at 45% 10%,#ff6c61, #ff6c61 10%, rgba(213,227,246,0) 70%), radial-gradient(circle at 100% 0,#c3ddec, #c3ddec 10%, rgba(206,114,90,0) 50%), radial-gradient(circle at 100% 100%,#002a30,#002a30 20%,rgba(0,50,57,0) 80%), radial-gradient(circle at 0 100%,#a4e5f9,rgba(0,50,57,0) 50%) white

*/

// Define variables first so they can overwite the values defined in the import files

$petrol: #003b43;
$petrol_dark: #003239;
$sky: #d5e3f6;
$salmon: #ce725a;

$highlight_bg:	radial-gradient(circle at 93.3% 0,rgba(213,227,246,.8),rgba(213,227,246,0) 70.71%),
				radial-gradient(circle at 0 0,rgba(206,114,90,.8),rgba(206,114,90,0) 70.71%),
				radial-gradient(circle at 0 0,rgba(169,75,50,.8),rgba(206,114,90,0) 70.71%),
				radial-gradient(circle at 0 100%,rgba(0,50,57,.8),rgba(0,50,57,0) 70.71%)
				$petrol_dark;
				

$lightgray: #dddddd;
$midgray: #9b9b9b;
$darkgray: #424242;

$font-color: #fff;
$focus-color: #61b1f8;

$img-base-path: '/mcont/img';
$shadow-depth: 10px;
$shadow-color: darken($lightgray, 13%);

/*$wrapper-width: 1366px;*/
$wrapper-width: 1640px;

@media print {
	$font-color: #000;
}

@import '_layout_base';
@import '_layout_head';
@import '_layout_footer';
@import '_layout_content';
@import '_layout_webshop';

@import 'fontawesome/fontawesome.scss';
@import 'fontawesome/solid.scss';
@import 'fontawesome/regular.scss';

@import 'blocks/_contactform';
@import 'blocks/_contentoverview';
@import 'blocks/_contentsearch';
@import 'blocks/_mediaportal';
@import 'blocks/_workflow';
@import 'blocks/_slider';
@import 'blocks/_pagehead_sprites';

@import 'blocks/_fonts';
@import 'blocks/_usertiles';
@import 'blocks/_contactform-custom';
@import 'blocks/_mediaportal-custom';
@import 'blocks/_doccontent';
@import 'blocks/_shop';

* {
	font-family: Ubuntu, sans-serif;
}

body {
	font-size: 16px;
	line-height: 1.5;

	&.lockscroll {
		overflow: hidden;
	}
}

// make sure the content is visible when alternate background is applied
.mainbody > * {
	z-index: 1;
}

#maincontent .highlight > *,
#maincontent > *,
.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 50px;
    width: $wrapper-width;
	
	&.medium {
		max-width: 700px;
		padding: 0;
		
		@media screen and (max-width: 720px) {
			max-width: 100%;
			padding-left: 40px;
			padding-right: 50px;
		}
		
		@media screen and (max-width: 480px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	&.small {
		max-width: 430px;
		padding: 0;
		
		@media screen and (max-width: 480px) {
			max-width: 100%;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	@media screen and (max-width: $wrapper-width) {
		width: 100%;
	}
	
	@media screen and (max-width: 480px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

div.mainbody {
	background: 
        radial-gradient(circle at 6.7% 0, rgba($salmon,1), rgba($salmon,0) 25.71%),
        radial-gradient(circle at 100% 0, rgba($sky,.8), rgba($sky,0) 70.71%),
        radial-gradient(circle at 0 100%, rgba($petrol_dark,.8), rgba($petrol_dark,0) 70.71%)
        $petrol_dark;
    color: $white;
	padding-top: 115px;
	position: relative;

	&::before {
		background: radial-gradient(circle at 100% 0,rgba(213,227,246,.8),rgba(213,227,246,0) 70.71%),radial-gradient(circle at 6.7% 0,rgba(106,192,198,.8),rgba(106,192,198,0) 70.71%),radial-gradient(circle at 0 100%,rgba(0,50,57,.8),rgba(0,50,57,0) 70.71%) #003239;
		content: '';
		height: 100%;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity .5s;
		width: 100%;
	}

	&.testclass::before {
		opacity: 1;
	}
}

#pagehead {
	&:not(.cmsloggedin):not(.wsloggedin) {
		height: 0;

		div ul {
			visibility: hidden;
		}
	}

	form {
		color: $black;
	}

	@media screen and (max-width: 1010px){
		#pagehead ul:not(.language) {
			float: right;
		}
	}

}

:focus-visible {
	outline: 1px solid $white;
}

h1, section#main div h1 {
	font-size: 3.55em;
	font-weight: normal;
	letter-spacing: 3px;
	line-height: 1.2;
	margin: 0;
}

section#main div h1 {
	margin-left: auto;
	margin-right: auto;
}

h2, section#main div h2 {
	font-size: 2em;
	font-weight: normal;
}

a {
	color: $font-color;
}

section#main {
	padding-top: 0;
	
	h1 {
		margin-top: 100px;
	}
	
	ol, ul {
		margin: 10px auto;
		
		li {
			margin-left: 20px;
		}
	}
	
	ul {
		list-style-image: url('#{$img-base-path}/tick.svg');

		&[list-style-type] {
			list-style-image: none;
		}
	}
	
	video {
		border: 0;
	}
	
	@media screen and (max-width: 480px) {
		padding: 0;
	}
}

// change link color for white background elements
#cookieconsent a,
#pagehead a{
	color: $black;
}

#pagehead :not(#usermenu) li > a {
	color: $font-color;
}

.flex {
	display: flex;

	&.row-wrap {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.css-fadein, #maincontent > * {
	opacity: 0;
	position: relative;
	top: 50px;
	transition: opacity 1s ease-out, top 1s ease-out;

	&.revealed {
		opacity: 1;
		top: 0;
	}
	
	@media print {
		opacity: 1;
		top: 0;
	}
}

.error:not(.messi-titlebox) {
	color: $white;
}

header {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
	margin-top: 0;
	padding: 40px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99 !important;

	&::before {
		background: linear-gradient(20deg, #29383b 0%, #6e94aa 100%);
		content: '';
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: opacity .5s;
		width: 100%;
		z-index: -1;
	}

	&.scrollbg::before,
	&:hover::before {
		opacity: 1;
	}
	
	#logo {
		background: url('#{$img-base-path}/logo.png') no-repeat;
		height: 34px;
		text-indent: -9999em;
		width: 168px;
		z-index: 20001;
	}
	
	#mainmenu_cont {
		margin-top: 9px; // Aligns menu with logo. Cannot be done through flex styling due to submenu
		width: auto;

		.nav-btn {
			background-color: transparent;
			background-image: url('#{$img-base-path}/menubtn.png');
			background-position: center;
			border: 0;
			border-radius: 0;
			font-size: 0;
			height: 44px;
			line-height: 44px;
			margin: 10px;
			position: absolute;
			right: 0;
			top: 5px;
			width: 44px;
			z-index: 10000;
		}

		#mainmenu li:not(.submenu-parent) ul {
			display: block !important;
		}
	
		#mainmenu {
			display: flex;
			float: none;
			justify-content: flex-end;
			letter-spacing: .1em;
			text-transform: uppercase;
			
			>li {
				float: none;
				
				&:not(:last-child){
					margin-right: 20px;
				}
				
				>a {
					padding: 0;
					text-align: left;
				}
				
				&.active > a {
					font-weight: bold;
				}
	
				ul {
					display: block;
					flex-grow: 1;
					margin-top: 10px;
					max-height: 0;
					max-width: 100%;
					opacity: 0;
					position: static;
					text-align: left;
					transition: max-height .5s .5s ease-out, opacity .5s, visibility 1s;
					visibility: hidden;
					width: 0;
	
					li{
						float: none;

						&.active a {
							font-weight: bold;
							text-decoration: none;
						}
	
						a {
							align-items: center;
							display: flex;
							height: auto;
							line-height: 1em;
							margin-top: 10px;
							max-height: 0;
							padding: 0;
							text-align: left;
							transition: max-height .5s .5s;
							white-space: nowrap;
							width: 100%;
	
							&:hover {
								text-decoration: none;
	
								&::after {
									opacity: 1;
								}
							}
	
							&::after {
								content: '•';
								display: inline-block;
								margin-left: 10px;
								opacity: 0;
								transition: opacity .3s;
							}
						}
					}
				}
	
				&:hover ul {
					display: block !important;
					max-height: 1000px;
					opacity: 1;
					transition: max-height .5s ease-out, opacity .5s .5s, visibility 0s;
					visibility: visible;
	
					li a {
						max-height: 1000px;
						transition: max-height 0s;
					}
				}
			}
	
			@media screen and (max-width: 1010px) {
				background-color: transparent;
				border: 0;
				border-radius: 0;
				position: static;
	
				li {
					border-bottom: 0;
	
					a {
						height: auto;
						line-height: 1em;
					}
				}
			}
	
			@media screen and (max-width: 1010px) {
				padding-left: 40px;
				
				>li {
					margin-top: 30px;
					
					&:first-child {
						margin-top: 0;
					}
					
					&.active > a {
						font-weight: bold;
					}
	
					ul {
						margin-top: 0;
	
						li a {
							margin-top: 15px;
						}
					}
				}
			}
			
			@media screen and (max-width: 480px){
				padding-left: 10px;
			}
		}
	}

	@media screen and (max-width: 1010px) {
		padding: 20px 40px;

		#mainmenu_cont {
			height: 100%;
			left: 0;
			margin-top: 0;
			position: absolute;
			top: 0;
			width: 100%;

			&::before {
				background: radial-gradient(circle at 0 100%, rgb(96, 80, 72),
							rgb(206, 114, 90) 100%);
				content: '';
				display: block;
				height: 100vh;
				left: -100vw;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: left 0s .3s, opacity .3s;
				width: 100vw;
			}

			&.menuopen {
				&::before {
					left: 0;
					opacity: 1;
					transition: left 0s, opacity .3s;
				}

				.nav-btn {
					background-image: url('#{$img-base-path}/menubtn_close.png?v=2');
				}
				
				#mainmenu {
					left: 0;
					opacity: 1;
					transition: opacity .5s;
					
					li:not(.submenu-parent) ul {
						max-height: 0;
						overflow: hidden;
						transition: max-height .3s ease;
					}
					
					li.open:not(.submenu-parent) ul {
						max-height: 500px;
						transition: max-height .3s .3s ease;
					}
				}
			}

			#mainmenu {
				display: block !important;
				flex-direction: column;
				height: calc(100vh - 130px);
				justify-content: flex-start;
				left: -100%;
				margin-top: 110px;
				opacity: 0;
				overflow-y: auto;
				/*padding-bottom: 40px;*/
				position: absolute;
				text-transform: none;
				top: 0;
				transition: left 0s .5s, opacity .5s;
				width: 100vw;
				
				>li {
					>a {
						font-size: 2em;
						font-weight: normal;
						text-transform: none;
						
						.menuarrow {
							display: none;
						}
					}
					
					ul {
						max-height: none;
						opacity: 1;
						visibility: visible;
						width: 100%;

						li a {
							font-size: 1.25em;
							font-weight: normal;
							max-height: none;
							
							&::after {
								display: none;
							}
							
							&.active,
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	
	@media screen and (max-width: 480px){
		padding: 20px 10px;
	}
}

.cmsloggedin {
	@media screen and (max-width: 1010px){
		header {
			padding: 40px 40px 20px;

			#mainmenu_cont .nav-btn {
				top: 24px;
			}
		}
	}
	
	@media screen and (max-width: 480px){
		header {
			padding: 40px 10px 20px 10px;
		}
	}
}

#loginfrm {
	color: $black;
}

#sidelinks {
	position: fixed;
	right: -218px;
	text-transform: uppercase;
	top: 50%;
	transform: rotate(90deg);

	a {
		font-weight: bold;
		margin: 0 10px;
		text-decoration: none;

		&:last-child {
			margin-right: 0;
		}
	}
	
	@media screen and (max-width: 480px) {
		bottom: 10px;
		font-size: 1.3em;
		left: 40px;
		opacity: 0;
		right: 10px;
		text-indent: -9999em;
		top: auto;
		transform: none;
		transition: opacity .5s, text-indent 0 .5s;
		width: 260px;
		z-index: 100;
		
		span {
			display: block;
		}
		
		span + a {
			margin-left: 0;
		}
		
		&.menuopen {
			opacity: 1;
			text-indent: 0;
			transition: opacity .5s, text-indent 0;
		}
	}
}

footer.wrapper {
	line-height: 1.4;
	margin-top: 150px;
	padding-bottom: 100px;

	&>.flex {
		justify-content: space-between;

		#footer_logo {
			flex: 1 1 auto;
			margin-top: 50px;
		}

		#footer_columns {
			flex: 1 1 auto;
			justify-content: space-between;

			@media screen and (max-width: 680px) {
				justify-content: center;
				flex-flow: column;

				> div {
					text-align: center;
					margin-bottom: 20px;
				}
			}
		}

		@media screen and (max-width: 860px){
			align-items: center;
			flex-flow: column;

			#footer_logo {
				margin-bottom: 30px;
			}

			#footer_columns {
				width: 100%;
			}
		}
	}
	
	address {
		font-style: normal;
	}
	
	:not(address) a {
		text-decoration: none;
	}

	#extralinks {
		justify-content: center;
		margin-top: 100px;
		text-transform: uppercase;

		#copyright {
			font-size: 1em;
			margin-right: 20px;
			margin-bottom: 20px;
		}
		
		li{
			&:not(:last-child) {
				margin-right: 10px;
			}
		}

		a {
			font-weight: bold;
			text-decoration: none;
		}
	}
	
	.col33 {
		float: none;
		text-align: left;
	}
	
	.foot_header {
		font-size: 20px;
	}
}

#top-btn {
	display: none !important;
}

div > div#scrollinfo {
    bottom: -60px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    min-width: auto;
    padding-right: 0;
    position: absolute;
    right: 0;
    text-align: center;
    // width: 70px;

	#scrollicon_animated {
		height: 22px;
		margin: 0 auto;
		width: 22px;
	}

	small {
		font-size: .7em;
	}
}

.splitter-70-30-cont {
	display: flex;
	justify-content: space-between;
	
	&::before, &::after {
		display: none;
	}
	
	.splitter-70 {
		margin-right: 0;
		width: calc(70% - 25px);
	}
	
	.splitter-30 {
		width: calc(30% - 25px);
	}
	
	@media screen and (max-width: 770px) {
		flex-direction: column;

		.splitter-70, .splitter-30 {
			width: 100%;
		}
	}
}

.content_title .splitter-70-30-cont {
	align-items: center;
}

.splitter-50-50-cont {
	display: flex;
	justify-content: space-between;
	
	.splitter-50 {
		margin-right: 0;
		width: calc(50% - 25px);
	}
	
	&::before, &::after {
		display: none;
	}

	@media screen and (max-width: 770px) {
		flex-direction: column;

		.splitter-50 {
			width: 100%;
		}
	}
}

.splitter-30x3-cont.withimg {
	.splitter-30{
		position: relative;

		>img::after {
			background: $white;
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			width: calc(100% - 100px) // subtract img width and padding
		}

		.lottie-player {
			display: inline-block;
			height: 80px;
			width: 80px;
		}
	}
}

.splitter-50, .splitter-30, .splitter-25 {
	float: none;
}

.morebutton,
.eventregbox {
	background: transparent;
	border: 1px solid $white;
	cursor: pointer;
	letter-spacing: 2px;
	padding: 5px 20px;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color .3s ease, color .3s ease;

	&:active,
	&:hover {
		background: $white;
		color: $petrol;
	}
}

// LANDING PAGE

#landing_head, #lh_slider {
	width: 100%;
}

#landing_head .slidecontent,
#maincontent .content_tile {
	align-items: center;
    display: flex;
	/*height: 600px;*/
	height: calc(100vh - 175px);
    justify-content: space-between;
    margin-bottom: 300px;
	margin-left: 0;
	margin-right: 0;
	padding-left: 40px;
	padding-right: 110px;
    position: relative;
	width: 100%;

	&::before {
		border: 1px solid $white;
		border-bottom: 0;
		border-left: 0;
		content: '';
		display: block;
		height: 25px;
		position: absolute;
		/*right: 55px;*/
		right: 60px;
		top: 0;
		width: 25px;
	}

	&::after {
		border: 1px solid $white;
		border-top: 0;
		border-left: 0;
		border-top: 0;
		bottom: 0;
		content: '';
		display: block;
		height: 25px;
		position: absolute;
		/*right: 55px;*/
		right: 60px;
		width: 25px;
	}
	
	&>img {
		display: block;
		margin-right: 30px;
		height: 100%;
		object-fit: cover;
		width: 50%;
	}
	
	&>video {
		background: $black;
		border: 0;
		display: block;
		margin-right: 30px;
		height: 100%;
		padding: 0;
		width: 50%;
	}

	&>div {
		min-width: 460px;
		padding-right: 30px;
		width: 45%;
		
		h1 {
			font-size: 60px;
			font-weight: normal;
			overflow-wrap: break-word;

			@media screen and (max-width: 860px) {
				font-size: 50px;
				margin-bottom: 20px;
				margin-top: 20px;
			}

			@media screen and (max-width: 480px) {
				font-size: 40px;
			}
		}
		
		h2 {
			font-size: 1.8em;
		}

		@media screen and (max-width: 860px) {
			min-width: auto;
		}
	}

	@media screen and (max-width: 1050px) {	
		align-items: center;
		flex-flow: column;
		height: auto;
		padding-right: 50px;

		&::after,
		&::before {
			display: none;
		}

		&>img,
		&>video {
			height: calc(100vh - 230px);
			margin-right: 0;
			min-height: 200px;
			width: 100%;
		
			@media screen and (max-width: 480px){
				height: calc(100vw - 30px)
			}
		}

		&>div {
			margin-top: 20px;
			padding-right: 0;
			width: 100%;
		}
	}
	
	@media screen and (max-width: 480px) {
		height: auto;
		padding-left: 10px;
		padding-right: 10px;
	}
}

#landing_head {
    margin-bottom: 300px;
	
	.bx-wrapper {
		margin-bottom: 0;
	}
	
	.slidecontent {
	    margin-bottom: 0;
	}
	
	h1 {
		margin-bottom: 20px;
	}
}

#landing_slider {
	align-items: center;
	display: flex;
	margin-bottom: 200px;
	min-height: 90vh;
	
	.slide {
		display: flex;
		justify-content: space-between;

		>* {
			width: 49%;
		}

		h1 {
			@media screen and (max-width: 860px) {
				font-size: 50px;
				margin-bottom: 20px;
			}

			@media screen and (max-width: 480px) {
				font-size: 40px;
			}
		}

		@media screen and (max-width: 860px) {
			flex-flow: column;

			>* {
				width: 100%;
			}
		}
	}
	
	@media screen and (max-width: 600px){
		min-height: 30vh;
	}
}

#landing_solutions {
	align-items: center;
	background: linear-gradient(180deg, transparent 300px, #fff 300px);
	display: flex;
	color: $black;
	min-height: 90vh;
	padding-bottom: 200px;
	
	>.wrapper {
		h1 {
			color: $font-color;
			font-size: 2em;
			font-weight: normal;
			margin-bottom: 30px;
		}
		
		.splitter-30x3-cont {
			justify-content: center;
		}

		.splitter-30 {
			margin-right: 40px;
			max-width: none;
			
			&:last-child {
				margin-right: 0;
			}

			img {
				margin-bottom: 30px;
				width: 100%;
			}

			h2 {
				font-size: 2em;
				font-weight: normal;
				margin-bottom: 0;

				a {
					color: inherit;
					text-decoration-color: transparent;
					transition: text-decoration-color .3s;

					&:active,
					&:hover {
						text-decoration-color: inherit;
					}
				} 
			}

			@media screen and (max-width: 480px) {
				width: 100%;
			}
		}

		@media screen and (max-width: 860px) {
			.splitter-30x3-cont {
				align-items: center;
				display: flex;
				flex-flow: column;

				.splitter-30 {
					margin-right: 0;
					width: 300px;
				}
			}
		}
	}
	
	@media screen and (max-width: 860px) {
		padding-bottom: 0;
	}
}

#landing_vision {
	align-items: center;
    background-color: $white;
    background-image:
        linear-gradient(180deg, rgba(213, 227, 246, 0.6), rgba(213,227,246,0)),
        radial-gradient(circle at 100% 60%, rgba(206, 114, 90, .9), rgba(206,114,90,0) 70.71%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 600px 750px;
    color: $black;
	display: flex;
	min-height: 90vh;
    padding-bottom: 300px;
    padding-top: 300px;
	
	.wrapper {
		align-items: center;
		display: flex;
		flex-flow: row-reverse;
		justify-content: space-between;
		position: relative;

		&::before {
			border: 1px solid $black;
			border-bottom: 0;
			border-right: 0;
			content: '';
			display: block;
			height: 25px;
			left: 40px;
			position: absolute;
			top: 0;
			width: 25px;
		}
		
		&::after {
			border: 1px solid $black;
			border-right: 0;
			border-top: 0;
			bottom: 0;
			content: '';
			display: block;
			height: 25px;
			left: 40px;
			position: absolute;
			width: 25px;
		}

		&>div {
			color: inherit;
			padding-left: 30px;
			width: 45%;

			&>h1 {
				color: inherit;
				line-height: 1;
				margin-bottom: 30px;

				@media screen and (max-width: 860px) {
					font-size: 50px;
				}
	
				@media screen and (max-width: 480px) {
					font-size: 40px;
				}
			}
		}
		
		&>img {
			display: block;
			width: 45%;
		}
		
		@media screen and (max-width: 780px) {
			align-items: center;
			flex-flow: column;
	
			&::after,
			&::before {
				display: none;
			}
	
			&>img {
				margin-right: 0;
				width: 280px;
			}
	
			&>div {
				padding-right: 0;
				width: 100%;
				
				h1 {
					margin-top: 40px;
				}
			}
		}
	}
}

.landing #main {
	margin-top: 200px;

	#lm_contentswitch_container {
		align-items: flex-end;
		display: flex;
		flex-flow: row;
		justify-content: start;


		h1 {
			font-size: 3em;
			margin-right: 20px;
		}

		#lm_contentswitch {
			display: flex;
			list-style: none;
			margin-bottom: 8px;
			text-transform: uppercase;

			li {
				&:not(:last-child) {
					margin-right: 10px;
				}

				a:not(.active) {
					text-decoration: none;
				}
			}
		}

		@media screen and (max-width: 680px) {
			align-items: flex-start;
			flex-flow: column;

			#lm_contentswitch {
				margin-top: 20px;
			}
		}
	}

	#lm_contentcontainer {
		margin-top: 80px;

		>div {
			opacity: 0;
			transition: opacity .5s ease;

			&:not(.active) {
				max-height: 0;
				overflow: visible;
			}

			&.active {
				max-height: 1000px;
				opacity: 1;
				overflow: auto;
				transition: max-height 0s .5s, opacity .5s .5s ease;
			}
		}
	}
}

#landing_demo,
#content_demo{
	align-items: center;
	display: flex;
	margin-top: 200px;
	min-height: 30vh;

	.tagline {
		>* {
			vertical-align: middle;
		}

		#logo_animated {
			display: inline-block;
			height: 3em;
			margin-right: 15px;
			width: auto;
		}

		span {
			font-size: 3em;
		}
	}

	.contact-btns {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		a {
			font-size: 1.2em;

			&:first-child {
				font-weight: bold;
				margin-right: 30px;
			}
		}

		@media screen and (max-width: 590px) {
			flex-flow: column;

			a {
				&:first-child {
					margin-bottom: 20px;
					margin-right: 0;
				}
			}
		}
	}
}

#landing_partners {
	align-items: center;
	display: flex;
	justify-content: space-between;
	/*margin-top: 250px;*/
	min-height: 30vh;

	strong {
		flex: 1 1 auto;
		font-size: 1.5em;
		font-weight: normal;
	}

	#lp_logos {
		align-items: center;
		display: flex;
		flex: 4 1 auto;
		justify-content: space-between;

		.previewimage {
			float: none;
			margin-right: 0;
			width: auto;
		}
	}

	@media screen and (max-width: 1050px) {
		display: block;
		padding-top: 100px;

		strong {
			display: none;
		}

		#lp_logos {
			flex-wrap: wrap;
			justify-content: space-around;

			.entrypreview {
				margin-top: 10px;
			}
		}
	}
}

// ENTRY LIST

#contenttitle {
	margin-bottom: 40px;
	
	@media screen and (max-width: 600px) {
		h1 {
			font-size: 2.75em;
		}
	}
	
	@media screen and (max-width: 480px) {
		h1 {
			font-size: 2.2em;
		}
	}
}

#entrylist {
	.entrypreview {
		align-items: center;
		border: 0;
		display: flex;
		justify-content: space-between;
		
		.previewimage {
			align-self: flex-start;
			flex: 0 0 auto;
			height: auto;
			/*height: 330px;*/
			margin-right: 60px;
			padding: 0;
			width: 400px;

			a {
				line-height: 1;
				width: 100%;
			}

			img {
				max-height: none;
				max-width: none;
				width: 100%;
			}
			
			@media screen and (max-width: 690px) {
				height: 165px;
				width: 200px;
			}
		}

		.entrypreviewtext {
			flex: 1 1 auto;
			flex-flow: column;
			padding: 0;

			h1 {
				font-size: 2.5em;
				margin-bottom: 10px;
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
			}
			
			.timestamp {
				margin-bottom: 10px;
			}

			a {
				margin-top: 40px;
				text-decoration: none;
			}

			.entrylink,
			.timestamp {
				text-transform: uppercase;
			}
		}
		
		@media screen and (max-width: 990px) {
			.previewimage {
				margin-right: 40px;
				width: 300px;
			}
		}
		
		@media screen and (max-width: 870px) {
			.previewimage {
				width: 200px;
			}
		}
		
		@media screen and (max-width: 760px) {
			align-items: flex-start;
			flex-direction: column;
			
			.previewimage {
				max-height: 165px;
				overflow: hidden;
				width: 100%;
				
				img {
					height: 100%;
					object-fit: cover;
				}
			}
		}
		
		@media screen and (max-width: 550px) {
			.entrypreviewtext h1 {
				font-size: 1.8em;
			}
		}
	}
}

#entrylist.clgrid {
	.entrypreview {
		text-align: center;
		width: 350px;

		> a {
			width: 100%;

			img {
				width: 100%;
			}
		}
	}
}

.pagecontrols {
	justify-content: space-between;

	.prevnext {
		border: 0;
		width: auto;

		&:hover {
			background: transparent;
		}
	}
}

// MAIN PAGE

#maincontent {
	#fullcontent {
		margin: 20vh auto;
		
		@media screen and (max-width: 620px) {
			font-size: 3em;
		}
	}
	
	.highlight {
		background: $highlight_bg;
		color: #fff;
		font-size: 1.1em;
		margin: 0;
		padding-bottom: 150px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 150px;
		width: 100%;
		
		&.withimg {
			background-color: transparent;
			background-image:
				linear-gradient(180deg, rgba(213, 227, 246, 0.6), rgba(213,227,246,0)),
				radial-gradient(circle at 100% 60%, rgba(206, 114, 90, .9), rgba(206,114,90,0) 70.71%);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 600px 750px;
			padding-bottom: 150px;
			padding-top: 150px;

			.wrapper {
				align-items: center;
				display: flex;
				justify-content: space-between;
				position: relative;

				&::before {
					border: 1px solid $white;
					border-bottom: 0;
					border-right: 0;
					content: '';
					display: block;
					height: 25px;
					left: 40px;
					position: absolute;
					top: 0;
					width: 25px;
				}

				&::after {
					border: 1px solid $white;
					border-right: 0;
					border-top: 0;
					bottom: 0;
					content: '';
					display: block;
					height: 25px;
					left: 40px;
					position: absolute;
					width: 25px;
				}

				>div {
					color: inherit;
					padding-bottom: 30px;
					padding-left: 30px;
					padding-top: 30px;
					width: 45%;
				}

				> img {
					display: block;
					width: 50%;
				}
				
				@media screen and (max-width: 620px) {
					flex-direction: column;
					
					&::before,
					&::after {
						display: none;
					}
					
					>div,
					>img {
						width: 100%;
					}

					>div {
						padding-left: 0;
					}
				}
			}
		}
	}

	>.fullwidth {
		margin: 0;
		padding: 0;
		width: 100%;

		>img {
			display: block;
			height: 530px;
			object-fit: cover;
			width: 100%;
		}
	}
}

.content_tile {
	margin-bottom: 150px;
}

.block_withimg {
	display: flex;
	justify-content: space-between;

	>div {
		flex: 1 1 auto;
	}

	@media screen and (max-width: 480px) {
		>img,
		>lottie-player {
			display: none;
		}
	}
}

#main .block_withimg {
	>img,
	>.lottie-player {
		align-self: flex-start;
		display: inline-block;
		height: auto;
		margin-right: 20px;
		max-width: 60px; // max-width is needed to show svg files in IE correctly
		min-width: 60px;

		&[src$='.png'] {
			width: 60px;
		}
	}

	>p {
		margin-right: 20px;
	}
}

section.mp-section {
	border: 0;
	/*border-bottom: 1px solid $font-color;*/
	padding-bottom: 20px;
	
	.mp-section-title {
		display: flex;
		flex-direction: row-reverse;
		padding: 0;
		
		.mpi-titlelink {
			flex: 1 1 auto;
		}
	
		.mp-section-titleprefix * {
			color: transparent;
			font-size: 0;
			
			&::before {
				color: #fff;
				content: "\f077";
				font-family: 'Font Awesome 5 Free';
				font-size: 25px;
				font-weight: 900;
			}
		}
		
		&.closed .mp-section-titleprefix *::before {
				content: "\f078";
		}
	}
	
	.mp-section-titleprefix:hover {
		background: transparent;
	}
	
	.mp-showhide-all {
		/*display: none;*/
		margin-right: 40px;
		
		&:hover {
			background: transparent;
		}
	}
	
	.container {
		border-bottom: 1px solid $font-color;
		padding: 20px 0 40px;
		position: relative;
	}

	.mp-section-container-hide {
		color: transparent;
		padding-bottom: 0;
	}
}

section.mp-section 
.mp-section 
.mp-section .container {
  padding: 0;
  margin: 20px 0;
}

// SLIDER

.previewslide {
	height: auto;
}

.team-slider {
	
	img {
		width: 100%;
	}
	
	strong {
		display: inline-block;
		font-size: 1.3em;
		font-weight: normal;
		margin-top: 20px;
	}
}

.bx-wrapper {
	.bx-controls-direction a {
		margin-top: 30px;
		top: auto;
	}
	
	.bx-prev {
		background: url('/mcont/img/slider-arrow-left.png') center no-repeat transparent;
		left: auto;
		right: 52px; // 20px margin to .bx-next
	}
	
	.bx-next {
		background: url('/mcont/img/slider-arrow-right.png') center no-repeat transparent;
		right: 0;
	}
	
	.bx-prev,
	.bx-next {
		border-radius: 50px;
		transition: background-color .3s;
		
		&:hover {
			background-color: rgba(0,0,0,.2);
			background-position: inherit;
		}
	}
}

.product-preview-slider {
	margin-bottom: 150px;
	
	.slide {
		display: flex;
		flex-direction: column;
		height: 330px;

		>* {
			flex: 0 0 auto;
		}

		img {
			max-height: 100px;
			max-width: 100px;
		}

		figcaption {
			font-size: 1.5em;
			margin-bottom: 10px;
		}

		>p {
			flex: 1 1 auto;
		}

		>a {
			height: auto;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
}

.refartslider .slide {
	display: flex;
	flex-direction: column;
	height: 330px;
	
	>.ws-articlewrapper {
		flex: 1 1 auto;
		margin-top: 0;
	
		img {
			max-height: 100px;
			max-width: 100px;
		}
	}
	
	>a {
		flex: 0 0 auto;
		height: auto;
		text-decoration: none;
		text-transform: uppercase;
	}
}

// CONTENT OVERVIEW

#moredoc {
	.group {
		&:first-child {
			border-top: 1px solid $black;
		}

		&:nth-child(2n+1) {
			background-color: transparent;
		}
	}
}

// EVENT FORM

.lb_regform {
	.close {
		background: $white;
		color: $petrol;
		padding: 10px 20px;
		
		.lightbox-close-x {
			display: none;
		}
	}

	#eventregform {
		a {
			color: $petrol;
		}
		
		// hiding all non-required fields
		#fs-ANM,
		[id^=d-p_GESCHLECHT],
		[id^=d-p_AKAD],
		[id^=d-p_POSTGRADUATE],
		[id^=d-p_TEL],
		[id^=d-p_MOBIL] {
			display: none;
		}
		
		input[type="submit"] {
			background: transparent;
			border: 1px solid $petrol;
			color: $petrol;
			cursor: pointer;
			letter-spacing: 2px;
			padding: 5px 20px;
			text-decoration: none;
			text-transform: uppercase;
			transition: background-color .3s ease, color .3s ease;

			&:active,
				&:hover {
				background: $petrol;
				color: $white;
			}
		}
		
		#d-agb {
			label {
				display: inline;
				
				&::after {
					content: '';
				}
			}
		}
	}
}

// FILE UPLOAD

section#main ul li.fileentry {
	margin: 0 15px 0 30px;
}

.file-upload {
	.btn.blue {
		background: 0 0;
		box-shadow: none;
		border: 1px solid $white;;
		border-radius: 0;
		color: $white;
		font-weight: normal;
		height: auto;
		padding: 10px;
		text-transform: uppercase;
	}
	
	.dropinfo .btn.blue{
		margin-left: 10px;
	}
}

.fileinfo-container {
	display: none !important;
}

section#main .file-upload-widget .files {
	margin:0;
}

// EDITOR

#tinymce {
	a {
		color: $petrol;
	}
}

// WORKFLOW

.swal2-popup {
	color: $petrol;
}

.swal2-actions {
	.swal2-styled.swal2-confirm {
		background: $petrol;

		&:focus {
			background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
			box-shadow: none;
		}
	}
	
	.swal2-styled.swal2-cancel:focus {
		background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
		box-shadow: none;
	}
} 

@media screen and (max-width: 480px) {
	section#main > [id^="wf-"] {
		margin-left: 10px;
		margin-right: 10px;
	}
}

#entrylist.wflist {
	background: transparent;
	/*border: 1px solid $white;*/
	
	.dochead {
		background: transparent;
		font-size: 1.1em;
		font-weight: normal;
		text-transform: uppercase;
	}
	
	.tr:not(.dochead) {
		border-bottom: 1px solid $white;
		text-decoration: none;

		&:hover,
			&:focus {
			text-decoration: underline;
		}
		
		&:not(.dochead):nth-child(2n+1) {
			background: transparent;
		}
	}
}

#wf-pagination a {
	border-color: $white;
	color: $white;
	transition: background .3s, color .3s;
	
	&:hover {
		background: $white;
		color: $petrol;
	}
}

#pagetitle.wf-title {
	margin-bottom: 50px;
	
	@media screen and (max-width: 760px) {
		font-size: 2.5em;
	}
	
	@media screen and (max-width: 480px) {
		font-size: 2em;
		padding-left: 10px;
	}
}

#wf-form {	
	fieldset {
		border: 0;
		padding: 0;
		padding-bottom: 15px;
		
		legend {
			margin: 0;
			font-size: 1.8em;
			font-weight: normal;
			letter-spacing: 3px;
			padding: 0;
		}
	}
	
	div[data-elemcont-id] > :not(br) {
		margin: 20px 0;
	}
	
	.wf-checktitle {
		font-size: 1.4em;
		
		@media screen and (max-width: 480px) {
			font-size: 1.4em;
		}
	}
	
	.wf-inputcont,
	.wf-selectcont,
	.wf-radiocont {
		align-items: center;
	}
	
	.wf-radioelemcont {
		background: transparent;
		border: 0;
		border-bottom: 1px solid $white;
		
		&.radiotiles{
			input[type="radio"]{
				&:checked + label {
					background: $white;
					color: $petrol;
				}
			}
			
			label {
				background: transparent;
				border: 1px solid $white;
				transition: background .3s, color .3s;
				
				&.active {
					background: $white;
					color: $petrol;
				}
				
				&:has(input:checked){ // not supported by firefox yet
					background: $white;
					color: $petrol;
				}
			}
		} 	
	}
	
	input:not([type="radio"]):not([type="submit"]),
	textarea {
		background: transparent;
		border: 0;
		border-bottom: 1px solid $white;
		color: $font-color;
		font-size: 1em;
		padding: 10px;
		
		&::placeholder {
			color: transparentize($font-color, .3);
			opacity: 1;
		}
		
		&:focus {
			background: transparent;
		}
	}

	.wf-inputcont.error {	
		input:not([type="radio"]):not([type="submit"]),
		input:not([type="radio"]):not([type="submit"]):focus {
			background: transparentize($red, .7);
		}
	}
	
	.wf-textareacont.error {
		textarea,
		textarea:focus {
			background: transparentize($red, .7);
		}
	}
	
	textarea {
		border: 1px solid;
		resize: none;
	}
	
	.wf-selectcont select {
		background: url(/mcont/img/chevron-down-solid.png) right center no-repeat transparent;
		border-bottom: 1px solid $white;
		color: $white;
		font-size: 1em;
		height: 42px;
		padding: 7px;
		
		option {
			color: $black;
		}
	}
	
	.file-upload-widget {
		
		.dropinfo {
			font-size: 1.2em;
			
			.btn{
				border: 0;
				font-size: 1em;
				height: auto;
				line-height: 34px;
				margin: 0;
				padding: 0;
				text-decoration: underline;
				text-transform: none;
			}
		}
		
		.btn {
			background: transparent;
			border: 1px solid $white;
			border-radius: 0;
			box-shadow: none;
			font-weight: normal;
			transition: background .3s, color .3s;
			
			&:hover {
				background: $white;
				color: $petrol;
			}
		}
		
		@media screen and (max-width: 480px) {
			.fileentry .flex > div {
				strong {
					display: inline-block;
					max-width: 150px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.wf-checktablecontainer {
		border: 1px solid $white;

		td {
			> * {
				font-size: 16px;
			}

			.wf-inputcont input, select {
				font-size: 16px;
			}

			input:not([type="radio"]):not([type="submit"]),
			input:not([type="radio"]):not([type="submit"]):focus,
			textarea,
			textarea:focus {
				background: transparentize($white, .85);
				
				&.wf-required {
					background: rgba(255,255,100,.3);
				}
			}

			&.del_row_container {
				min-width: auto;
				
				.del_row {
					text-decoration: none;
				}
			}
		}
	}
	
	#wf-submit {
		color: $white;
		font-size: 1em;
		height: 39px;
		margin: 0;
		
		&:hover {
			color: $petrol;
		}
	}
	
	#wf-formcontrols a {
		margin-bottom: 0;
		margin-top: 0;
	}
}

#webactionform {
	input:not([type="radio"]):not([type="submit"]),
	textarea {
		background: transparent;
		border: 0;
		border-bottom: 1px solid $white;
		color: $font-color;
		padding: 10px;
		
		&::placeholder {
			color: $font-color;
			opacity: 1;
		}
		
		&:focus {
			background: transparent;
		}
	}
	
	textarea {
		border: 1px solid;
		display: block;
		resize: none;
	}
	
	#d-submit {
		margin-top: 20px;
		
		#submit {
			background: transparent;
			color: $white;
			font-size: .9em;
			height: 39px;
			margin: 0;
			padding: 2px 15px;
			text-transform: uppercase;
			transition: background .3s, color .3s;

			&:hover {
				background: $white;
				color: $petrol;
			}
		}
	}
}
