@font-face {
	font-family: Ubuntu;
	font-style: normal;
	src: url('/mcont/css/Ubuntu/Ubuntu-Light.ttf');
}
/*@font-face {
	font-family: Ubuntu;
	font-weight: bold;
	src: url('/mcont/css/Ubuntu/Ubuntu-Regular.ttf');
}*/
@font-face {
	font-family: Ubuntu;
	font-style: italic;
	src: url('/mcont/css/Ubuntu/Ubuntu-Italic.ttf');
}
/*@font-face {
	font-family: Ubuntu;
	font-style: italic;
	font-weight: bold;
	src: url('/mcont/css/Ubuntu/Ubuntu-BoldItalic.ttf');
}*/
@font-face {
	font-family: Ubuntu;
	font-weight: 400;
	src: url('/mcont/css/Ubuntu/Ubuntu-Light.ttf');
}

@font-face {
	font-family: Ubuntu;
	font-style: italic;
	font-weight: 400;
	src: url('/mcont/css/Ubuntu/Ubuntu-LightItalic.ttf');
}
@font-face {
	font-family: Ubuntu;
	font-weight: 700;
	src: url('/mcont/css/Ubuntu/Ubuntu-Bold.ttf');
}

@font-face {
	font-family: Ubuntu;
	font-style: italic;
	font-weight: 700;
	src: url('/mcont/css/Ubuntu/Ubuntu-BoldItalic.ttf');
}
