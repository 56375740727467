#contactform_info {
	justify-content: space-between;

	>div:first-child {
		width: 40%;
	}

	>div:last-child {
		width: calc(60% - 40px);
	}

	@media screen and (max-width: 880px){
		flex-direction: column;

		>div:first-child,
		>div:last-child {
			margin-bottom: 60px;
			width: 100%;
		}
	}

	@media screen and (max-width: 480px) {
		>div:last-child {
			flex-direction: column;
			
			.contactform_partner {
				align-items: center;
				width: 100%;

				&:first-child {
					margin-bottom: 60px;
				}
			}
		}
	}
}

.contactform_partner {
    display: flex;
    flex-direction: column;
	margin-right: 60px;

	&:last-child {
		margin-right: 0;
	}

	strong {
		font-size: 1.5em;
		font-weight: normal;
		margin-top: 15px;
	}
}

#morecontactform {
	input, textarea {
		&:focus-visible {
			outline: none;
		}
	}

	input[type="text"],
	input[type="email"],
	textarea {
		background: transparent;
		border: 0;
		border-bottom: 1px solid $white;
		color: $font-color;
		margin-right: 50px;
		margin-top: 20px;
		padding: 10px;
		
		&::placeholder {
			color: $font-color;
			opacity: 1;
		}

		@media screen and (max-width: 480px) {
			margin-right: 0;
			width: 100%;
		}
	}
	
	label[for="ANFRAGETEXT"] {
		display: block;
		margin-top: 50px;
	}
	
	textarea {
		border: 1px solid $white;
		margin-bottom: 20px;
		margin-right: 0;
		width: 100%;
	}
	
	#name999 {
		display: none;
	}
	
	#contactform_foot {
		display: flex;
		justify-content: space-between;
	}
	
	input[type="submit"] {
		background: transparent;
		border: 1px solid $white;
		color: $white;
		letter-spacing: 2px;
		margin: 0;
		padding: 5px 20px;
		text-decoration: none;
		text-transform: uppercase;
		transition: background-color .3s ease, color .3s ease;
		
		&:hover,
		&:focus {
			background: $white;
			color: $petrol;
		}
	}
}

// checkbox styling

$uiToggleSize: 20px;
$uiToggleIndent: .4em;
$uiToggleBorderWidth: 1px;
$uiToggleColor: $white;
$uiToggleDisabledColor: #868e96;
$uiToggleBgColor: transparent;
$uiToggleArrowWidth: 2px;
$uiToggleArrowColor: $petrol;

.toggle{
	display: block;
	margin: 0;
	position: relative;
	
}

.toggle__input{
	position: absolute;
	left: -99999px;
}

.toggle__label{
	display: inline-flex;
	cursor: pointer;
	min-height: $uiToggleSize;
	padding-left: calc( #{$uiToggleSize} + #{$uiToggleIndent} );
}

.toggle__label:before, .toggle__label:after{
	content: "";
	box-sizing: border-box;  
	width: 1em;
	height: 1em;
	font-size: $uiToggleSize;

	position: absolute;
	left: 0;
	top: 4px;
}

.toggle__label:before{
	border: $uiToggleBorderWidth solid $uiToggleColor;
	z-index: 2;
}

.toggle__input:disabled ~ .toggle__label:before{
	border-color: $uiToggleDisabledColor;
}

/*.toggle__input:focus ~ .toggle__label:before{
	//box-shadow: 0 0 0 2px $uiToggleBgColor, 0 0 0px 4px $uiToggleColor;
}

.toggle__input:not(:disabled):checked:focus ~ .toggle__label:after{
	//box-shadow: 0 0 0 2px $uiToggleBgColor, 0 0 0px 4px $uiToggleColor;
}*/

.toggle__input:not(:disabled) ~ .toggle__label:after{
	background-color: $uiToggleColor;
	opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label:after{
	opacity: 1;
}

.toggle__text{
	margin-top: auto;
	margin-bottom: auto;
}

/*
The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle
*/

.toggle__text:before{
	content: "";
	box-sizing: border-box;
	width: 0;
	height: 0;
	font-size: $uiToggleSize;

	border-left-width: 0;
	border-bottom-width: 0;
	border-left-style: solid;
	border-bottom-style: solid;
	border-color: $uiToggleArrowColor;

	position: absolute;
	top: .7428em;
	left: .2em;
	z-index: 3;

	transform-origin: left top;
	transform: rotate(-40deg) skew(10deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
	width: .5em;
	height: .25em;
	border-left-width: $uiToggleArrowWidth;
	border-bottom-width: $uiToggleArrowWidth;
	will-change: width, height;
	transition: width .1s ease-out .2s, height .2s ease-out;
}

/* 
The demo skin
*/

.toggle__label:before, .toggle__label:after{
	border-radius: 2px;
}

/* 
The animation of switching states
*/

.toggle__input:not(:disabled) ~ .toggle__label:before,
.toggle__input:not(:disabled) ~ .toggle__label:after{
	opacity: 1;
	transform-origin: center center;
	will-change: transform;
	transition: transform .2s ease-out;
}

.toggle__input:not(:disabled) ~ .toggle__label:before{
	transform: rotateY(0deg);
	transition-delay: .2s;
}

.toggle__input:not(:disabled) ~ .toggle__label:after{
	transform: rotateY(90deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label:before{
	transform: rotateY(-90deg);
	transition-delay: 0s;
}

.toggle__input:not(:disabled):checked ~ .toggle__label:after{
	transform: rotateY(0deg);
	transition-delay: .2s;
}

.toggle__text:before{
	opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
	opacity: 1;
	transition: opacity .1s ease-out .3s, width .1s ease-out .5s, height .2s ease-out .3s;
}