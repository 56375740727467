#ws-productdesc .product-slider-container {
	background: transparent;
	box-shadow: none;
}

section#main {
	#ws-techdesc {
		margin-bottom: 40px;

		h2 {
			color: $white;
			font-size: 1.5em;
			margin-bottom: 20px;
		}
	}

	#ws-specs {
		margin-bottom: 200px;
		
		h2 {
			background: $white;
			color: $petrol;
			font-size: 1.5em;
			margin-bottom: 40px;
			padding: 10px;
		}

		.ws-specs {
			tr {
				margin-top: 10px;
				
				&:nth-child(2n) {
					background: transparent;
				}
			}

			td:first-child {
				text-align: right;
			}

			td:last-child {
				text-indent: -5px;
				padding-left: 20px;

				&:before {
					content: '•';
					display: inline-block;
					margin-right: 5px;
				}
			}
		}
	}

	#ws-documents {
		background: $white;
		color: $petrol;
		margin: 200px 0;
		padding: 10px;

		h2 {
			font-size: 1.5em;
		}

		a {
			color: inherit;
		}
	}
}

.refartslider {
	.slide {
		border: 0;
		
		&.ws-article .ws-articleimg img {
			margin: 0;
		}
		
		a {
			margin-top: 20px;
			text-decoration: none;
			text-transform: uppercase;
			vertical-align: middle;
			
			i {
				font-size: .8em;
				vertical-align: middle;
			}
		}
	}
}