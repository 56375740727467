.tile {
	background: $petrol;
	color: $white;
	display: flex;
	flex-direction: column;
	font-size: 0.8em;
	height: 250px;
	justify-content: center;
	text-decoration: none;
	width: 250px;
	
	> div:first-child {
		font-size: 1.3em;
		margin-bottom: 10px;
	}
}
